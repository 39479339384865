import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { AkeliusIDToken } from '../models/akelius-id-token.model';
import { RequestHelpers } from '../helpers/request.helpers';

export const authInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const appConfig = inject<AppConfig>(APP_CONFIG);

  if (!RequestHelpers.isIntranetRequest(req, appConfig)) {
    return next(req);
  }

  return authService.getAccessTokenSilently().pipe(
    switchMap((token: string) => {
      return authService.idTokenClaims$.pipe(
        take(1),
        map((idToken) => [token, idToken as unknown as AkeliusIDToken] as const),
      );
    }),
    mergeMap(([token, idToken]) => {
      const reqWithHeaders = req.clone({
        withCredentials: false,
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'x-akelius-id-token': idToken?.__raw ?? '',
        },
      });

      return next(reqWithHeaders);
    }),
  );
};
