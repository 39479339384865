import { Injectable, inject } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { map, filter, switchMap, catchError } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { jwtDecode } from 'jwt-decode';
import { GoogleAnalyticsHelpers } from '../helpers/google-analytics.helpers';
import { TenantOption } from './tenant.service';
import { AuthService as Auth0 } from '@auth0/auth0-angular';
import {
  LOCAL_STORAGE_TENANT_ID,
  STORAGE_OBJECT,
  URL_TENANT_ID,
  WINDOW_OBJECT,
} from '../../injection-tokens/injection-tokens';

export const STORAGE_KEY_TENANT_ID: string = 'selectedTenantId';

export interface LoginOptions {
  redirectPath?: string;
  organisationId?: string | null;
  silentLogin?: boolean;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _window = inject(WINDOW_OBJECT);
  private storedTenantId = inject(LOCAL_STORAGE_TENANT_ID);
  private urlTenantId = inject(URL_TENANT_ID);
  private storage = inject(STORAGE_OBJECT);
  private auth0 = inject(Auth0);

  isAuthenticated$ = this.auth0.isAuthenticated$;
  idTokenClaims$ = this.auth0.idTokenClaims$;
  user$ = this.auth0.user$;
  isLoading$ = this.auth0.isLoading$;

  jwtDecode = jwtDecode;
  readonly INTRANET_ROLES = 'https://intranet.akelius.com/claims/roles';
  readonly TENANTS = 'https://akelius.com/claims/available-tenants';
  readonly ACTIVE_TENANT = 'https://akelius.com/claims/tenant-name';
  readonly DEPARTMENT = 'https://akelius.com/department';
  readonly STORAGE_CALLBACK_ITEM = 'storageAuthCallbackItem';

  constructor() {
    this.metadataForAnalytics$.subscribe({
      next: (metadata: { department: string; country: string }) => {
        GoogleAnalyticsHelpers.setDataLayerUser(metadata);
      },
      error: (error) => console.error('Error retrieving user data for analytics:', error),
    });
  }

  metadataForAnalytics$: Observable<{ department: string; country: string }> = this.auth0.isAuthenticated$.pipe(
    filter((authenticated) => authenticated),
    switchMap(() => this.auth0.idTokenClaims$),
    filter((token) => !!token),
    map((idTokenPayload) => {
      return {
        department: idTokenPayload[this.DEPARTMENT],
        // akelius has overwritten the country claim with an object so we need to cast it to a Record<string, string>
        country: idTokenPayload?.address
          ? (idTokenPayload.address as unknown as Record<string, string>)['country']
          : '',
      };
    }),
  );

  login(options?: LoginOptions) {
    const defaultOptions: LoginOptions = {
      redirectPath: '/',
      organisationId: null,
      silentLogin: false,
    };

    const configWithDefaults = { ...defaultOptions, ...options };

    // if the login was triggered by the organisation switcher, we will have the organisationId in the options
    // otherwise we will use the tenantId from the URL or the stored tenantId
    const organisationId = configWithDefaults.organisationId
      ? configWithDefaults.organisationId
      : this.getSelectedTenantId();

    this.auth0.loginWithRedirect({
      authorizationParams: {
        // can get an invalid state error if the redirect URI is cached
        redirect_uri: `${this._window.location.origin}/login?cache=${Date.now()}`,
        ...(organisationId ? { organization: organisationId } : {}),
        ...(configWithDefaults.silentLogin ? { prompt: 'none' } : {}),
      },
      appState: { target: configWithDefaults.redirectPath },
    });
  }

  getAccessTokenSilently() {
    return this.auth0.getAccessTokenSilently().pipe(
      catchError((error) => {
        console.error('Error getting access token silently, triggering login', error);
        this.login();
        return NEVER;
      }),
    );
  }

  logout() {
    this.storage.localStore.removeItem(STORAGE_KEY_TENANT_ID);
    this.auth0.logout();
  }

  hasRole(role: string | string[]): Observable<boolean> {
    const roles = Array.isArray(role) ? role : [role];

    return this.auth0.getAccessTokenSilently().pipe(
      map((token) => this.jwtDecode<{ [key: string]: string[] }>(token)),
      map((accessTokenPayload) => {
        return accessTokenPayload[this.INTRANET_ROLES]
          ? !!accessTokenPayload[this.INTRANET_ROLES].filter((r: string) => roles.includes(r)).length
          : false;
      }),
    );
  }

  // get the tenants the user is allowed to access
  getTenants$(): Observable<TenantOption[]> {
    return this.getAccessTokenSilently().pipe(
      map((token: string) => this.jwtDecode<{ [key: string]: unknown[] }>(token)),
      map((accessTokenPayload: { [key: string]: unknown[] }) => {
        return accessTokenPayload[this.TENANTS].map((claimValue) => claimValue as TenantOption) ?? [];
      }),
    );
  }

  getActiveTenant$(): Observable<string> {
    return this.getAccessTokenSilently().pipe(
      map((token: string) => this.jwtDecode<{ [key: string]: unknown }>(token)),
      map((accessTokenPayload: { [key: string]: unknown }) => {
        return (accessTokenPayload[this.ACTIVE_TENANT] as string) ?? '';
      }),
    );
  }

  private getSelectedTenantId(): string | null {
    if (this.urlTenantId) {
      return this.urlTenantId;
    }

    return this.storedTenantId ?? null;
  }
}
