import { APP_CONFIG, AppConfig } from '../../../app.config';
import {
  STORAGE_OBJECT,
  URL_TENANT_ID,
  WINDOW_OBJECT,
  LOCAL_STORAGE_TENANT_ID,
} from '../../injection-tokens/injection-tokens';
import { getBaseHref, getTenantId, storageFactory, windowFactory, getStoredTenantId } from '../../app-config';
import { APP_BASE_HREF } from '@angular/common';

const provideEnvironment = (config: AppConfig) => {
  return [
    { provide: APP_CONFIG, useValue: config },
    { provide: WINDOW_OBJECT, useFactory: windowFactory },
    { provide: STORAGE_OBJECT, useFactory: storageFactory },
    { provide: APP_BASE_HREF, useFactory: getBaseHref },
    { provide: URL_TENANT_ID, useFactory: getTenantId },
    { provide: LOCAL_STORAGE_TENANT_ID, useFactory: getStoredTenantId, deps: [STORAGE_OBJECT] },
  ];
};

export default provideEnvironment;
