import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PropertyDataService } from '../services/property-data.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationService } from '../../../core/services/notification.service';
import { ContextFacadeService } from '../../../core/facades/context-facade.service';
import { AssetSummaryInfo } from '../models/interfaces';
import { NotFoundError } from '../../../core/common/not-found-error';

export const assetDeepLinkGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const dataService = inject(PropertyDataService);
  const router = inject(Router);
  const notificationService = inject(NotificationService);
  const contextFacade = inject(ContextFacadeService);

  if (state?.url.includes('permalink')) {
    contextFacade.resetContext();
  }

  return dataService.loadAssetInfoByObjectId(route.params['assetId']).pipe(
    switchMap((assetInfo: AssetSummaryInfo) => {
      if (assetInfo.parentObjectIds.length === 1) {
        return of({
          assetInfo,
          parentInfo: assetInfo,
        });
      } else {
        const propertyId = assetInfo.parentObjectIds[assetInfo.parentObjectIds.length - 2];
        return dataService.loadAssetInfoByObjectId(propertyId).pipe(
          map((parentInfo) => ({
            assetInfo,
            parentInfo,
          })),
        );
      }
    }),
    map((assetsData: { assetInfo: AssetSummaryInfo; parentInfo: AssetSummaryInfo }) => {
      return router.createUrlTree(
        [
          'property-catalog',
          assetsData.parentInfo.objectId,
          assetsData.parentInfo.displayName,
          assetsData.assetInfo.type,
          assetsData.assetInfo.objectId,
          assetsData.assetInfo.displayName,
        ],
        {
          queryParams: route.queryParams,
        },
      );
    }),
    catchError((err) => {
      if (err instanceof NotFoundError) {
        notificationService.showErrorDialog('core.notification.notFoundAlreadyDeleted');
        return of(router.createUrlTree(['properties', 'propertylist', 'geography_filters']));
      } else {
        throw err;
      }
    }),
  );
};
