@if ((loading$ | async) === true) {
  <div class="page-spinner">
    <mat-spinner></mat-spinner>
  </div>
}

@let authServiceLoading = (authService.isLoading$ | async);

@if (!authServiceLoading) {
  <router-outlet></router-outlet>
}
