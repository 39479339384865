import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideFormly } from './app/shared/functional/ui-formly/ui-formly.module';
import { AppComponent } from './app/app/app.component';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app/app.routes';
import { normalizeInterceptor } from './app/core/interceptors/normalize.interceptor';
import { authInterceptor } from './app/core/interceptors/auth.interceptor';
import { langInterceptor } from './app/core/interceptors/lang.interceptor';
import { tenantInterceptor } from './app/core/interceptors/tenant.interceptor';
import { staffAuthInterceptor } from './app/features/staff-app/interceptors/auth.interceptor';
import { provideApollo } from 'apollo-angular';
import { generateApolloConfig } from './app/features/staff-app/apolloProvider';
import { GlobalErrorHandlerProvider } from './app/core/services/global-error-handler.service';
import provideMaterialConfig from './app/core/providers/material.poviders';
import provideEnvironment from './app/core/providers/environmentSetup.providers';
import provideIcons from './app/core/providers/icons.providers';
import { CustomUrlSerializerProvider } from './app/core/services/url-serializer';
import { provideAuth0 } from '@auth0/auth0-angular';
import TENANT_ID_STORAGE_KEY from './constants/tenant-storage-key';

const requestInit: RequestInit = {
  cache: 'no-cache',
};

fetch('/assets/app-config/config.json', requestInit)
  .then((res) => res.json())
  .then((appConfig) => {
    console.info('Intranet running with config:', appConfig);

    if (environment.production) {
      enableProdMode();
    }

    const location = window?.location.pathname;
    const localStorageTenant = localStorage?.getItem(TENANT_ID_STORAGE_KEY);

    const isAkeliusTenant = location.startsWith('/t/akelius-') || localStorageTenant?.startsWith('akelius-');

    bootstrapApplication(AppComponent, {
      providers: [
        CustomUrlSerializerProvider,
        GlobalErrorHandlerProvider,
        provideIcons(),
        provideFormly(),
        provideMaterialConfig(),
        provideEnvironment(appConfig),
        provideAuth0({
          domain: appConfig.auth0.domain,
          clientId: appConfig.auth0.clientID,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
          useRefreshTokensFallback: true,
          authorizationParams: {
            audience: appConfig.auth0.audience,
            scope: appConfig.auth0.scope,
            ...(isAkeliusTenant ? { connection: appConfig.auth0.apiConnection } : {}),
            ...(appConfig.auth0.organization ? { organization: appConfig.auth0.organization } : {}),
            redirect_uri: `${window?.location.origin}`,
          },
        }),
        provideHttpClient(
          withInterceptors([
            authInterceptor,
            staffAuthInterceptor,
            langInterceptor,
            normalizeInterceptor,
            tenantInterceptor,
          ]),
        ),
        provideAnimations(),
        provideRouter(appRoutes),
        provideApollo(generateApolloConfig(appConfig)),
      ],
    }).catch((err) => console.error(err));
  });
