import { IconService } from './core/services/icon.service';
import { Observable } from 'rxjs';
import { StorageObject } from './core';
import { STORAGE_KEY_TENANT_ID } from './core/services/auth.service';

export function initializeApp(iconService: IconService): () => Observable<[string[], string[]]> {
  return () => iconService.registerIcons();
}

export function windowFactory(): Window {
  return window;
}

export function storageFactory(): StorageObject {
  return {
    localStore: localStorage,
    sessionStore: sessionStorage,
  };
}

export function getBaseHref() {
  const segments = location.pathname.split('/');

  return segments[1] === 't' ? `/${segments[1]}/${segments[2]}` : '/';
}

export function getTenantId() {
  const segments = location.pathname.split('/');

  return segments[1] === 't' ? segments[2] : null;
}

export function getStoredTenantId(storage: StorageObject) {
  return storage.localStore.getItem(STORAGE_KEY_TENANT_ID);
}
